<template>
  <div class="py-50">
    <div v-for="task in taskList" :key="task.id" class="mb-0">
      <b-card-actions
        v-if="task.status"
        :title="`Task ID: ${task.id}`"
        action-close
        @close="stopWatchingTaskStatus(task.id)"
      >
        <div v-if="task.status === 'SUCCESS'" class="w-100">
          <div class="d-inline-flex w-100 justify-content-start mb-0">
            <feather-icon
              icon="CheckIcon"
              class="text-success font-large-2 mx-2 mt-50"
            />
            <div class="mt-50">
              <h4 class="text-success mb-0">Task complete</h4>
              <p class="mb-50">
                The task has finished, you should now be able to open the model or snapshot from either the
                <span class="text-primary font-weight-bold">Model or Snapshots</span> tabs below.
              </p>
              <b-button
                class="pl-0"
                size="sm"
                variant="flat-primary"
                @click="downloadLogFile(task.id)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                Download logs
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="task.status === 'ERROR'" class="w-100">
          <div class="d-inline-flex w-100 justify-content-start mb-0">
            <feather-icon
              icon="AlertCircleIcon"
              class="text-danger font-large-2 mx-2 mt-50"
            />
            <div class="mt-50">
              <h4 class="text-danger mb-0">Task error</h4>
              <p class="mb-0">
                An error occurred while processing the task,
                we have attempted to revert any changes made during this process.
              </p>
              <p class="mb-50">
                Please check your data and try again, if the errors persist,
                you will need to contact an administrator.
              </p>
              <b-button
                class="pl-0"
                size="sm"
                variant="flat-primary"
                @click="downloadLogFile(task.id)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                Download logs
              </b-button>
              <pre>{{ task }}</pre>
            </div>
          </div>
        </div>
        <div v-if="task.status === 'PROGRESS'" class="w-100">
          <h5 class="text-primary font-weight-bold mb-50">
            {{ task.info.msg }}
          </h5>
          <b-progress
            v-if="task.info.total"
            :value="task.info.created"
            :max="task.info.total"
            class="bg-facebook"
            style="min-height: 1.5rem;"
            show-value
            striped
            animated
          />
          <hollow-dots-spinner
            v-else
            style="min-height: 1.5rem;"
            class="align-content-center"
          />
        </div>
      </b-card-actions>
    </div>

    <b-tabs id="menu" class="mb-2">
      <b-tab title="Models">
        <h5 class="ml-1 mt-2">
          My Models
        </h5>
        <hr>
        <model-table v-if="userModels.length > 0" :table-data="userModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Models
        </h5>
        <hr>
        <model-table v-if="sharedModels.length > 0" :table-data="sharedModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Models
        </h5>
        <hr>
        <model-table v-if="publicModels.length > 0" :table-data="publicModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public models found.</span>
        </div>
      </b-tab>

      <!-- Model Snapshots Tab -->
      <b-tab title="Snapshots">
        <h5 class="ml-1 mt-2">
          My Snapshots
        </h5>
        <hr>
        <model-snapshots-table v-if="userSnapshots.length > 0" id="model-snapshots-table" :table-data="userSnapshots" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Snapshots
        </h5>
        <hr>
        <model-snapshots-table v-if="sharedSnapshots.length > 0" :table-data="sharedSnapshots" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Snapshots
        </h5>
        <hr>
        <model-snapshots-table v-if="publicSnapshots.length > 0" :table-data="publicSnapshots" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public snapshots found.</span>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import HollowDotsSpinner from '@/components/Spinners/HollowDotsSpinner.vue'
import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import store from '@/store'
import ModelTable from '@/views/Home/ModelTable.vue'
import ModelSnapshotsTable from '@/views/Home/ModelSnapshotsTable.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'ModelsBrowser',
  components: {
    BCardActions,
    HollowDotsSpinner,
    ModelTable,
    ModelSnapshotsTable,
  },
  setup(props, context) {
    const userModels = ref([])
    const getUserModels = () => {
      axiosIns
        .get('/api/v2/models/user')
        .then(({ data }) => { userModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getUserModels] ${e}`))
    }

    const sharedModels = ref([])
    const getSharedModels = () => {
      axiosIns
        .get('/api/v2/models/shared')
        .then(({ data }) => { sharedModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getSharedModels] ${e}`))
    }

    const publicModels = ref([])
    const getPublicModels = () => {
      axiosIns
        .get('/api/v2/models/public')
        .then(({ data }) => { publicModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getPublicModels] ${e}`))
    }

    const publicSnapshots = ref([])
    const sharedSnapshots = ref([])
    const userSnapshots = ref([])
    const fetchSnapshots = () => {
      coreService
        .get('/v1/model/temp')
        .then(({ data }) => {
          publicSnapshots.value = data.public_models
          sharedSnapshots.value = data.shared_models
          userSnapshots.value = data.user_models
        })
        .catch(e => console.error(e))
    }

    const getAllModels = () => {
      getUserModels()
      getSharedModels()
      getPublicModels()
      fetchSnapshots()
    }
    getAllModels()

    const taskIdList = computed(() => store.state.workerTasks.taskList)
    const taskList = ref([])
    const getTaskStatus = taskId => {
      coreService.get(`/v1/tasks/${taskId}/status`)
        .then(({ data }) => {
          taskList.value = { ...taskList.value, [taskId]: data }
          if (['PROGRESS', 'PENDING'].includes(data.status)) {
            setTimeout(() => getTaskStatus(taskId), 500)
          } else if (data.status === 'SUCCESS') {
            getAllModels()
          } else {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: `An error occurred on task ${taskId}`,
                text: data.result,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(e => console.error(e))
        .finally(() => { store.state.model.snapshot_task_id = null })
    }
    const pollTaskStatuses = () => {
      taskIdList.value.forEach(taskId => {
        getTaskStatus(taskId)
      })
    }
    pollTaskStatuses()
    const stopWatchingTaskStatus = taskId => {
      store.commit('workerTasks/REMOVE_TASK_FROM_LIST', taskId)
    }
    const downloadLogFile = taskId => {
      coreService.get(`/v1/tasks/${taskId}/log`, { responseType: 'blob' })
        .then(response => {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'text/plain' }))
          const fileLink = document.createElement('a')
          fileLink.href = fileUrl
          const fileName = `task_${taskId}.log`
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(e => {
          console.error(e)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: `There was an error attempting to download the task log file.  ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      userModels,
      sharedModels,
      publicModels,
      publicSnapshots,
      sharedSnapshots,
      userSnapshots,
      taskList,
      taskIdList,
      stopWatchingTaskStatus,
      downloadLogFile,
    }
  },
}
</script>
